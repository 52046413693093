import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="toggle-button"
export default class extends Controller {
  static targets = ["button", "span", "input"];

  connect() {}

  toggle() {
    if (this.buttonTarget.classList.contains("toggle-enabled")) {
      this.buttonTarget.classList.remove("toggle-enabled", "bg-purple-600");
      this.buttonTarget.classList.add("toggle-disabled", "bg-gray-200");
      this.buttonTarget.setAttribute("aria-checked", false);
      this.spanTarget.classList.remove("translate-x-5");
      this.spanTarget.classList.add("translate-x-0");
      this.inputTarget.value = "false";
    } else {
      this.buttonTarget.classList.remove("toggle-disabled", "bg-gray-200");
      this.buttonTarget.classList.add("toggle-enabled", "bg-purple-600");
      this.buttonTarget.setAttribute("aria-checked", true);
      this.spanTarget.classList.remove("translate-x-0");
      this.spanTarget.classList.add("translate-x-5");
      this.inputTarget.value = "true";
    }
    this.element.closest("form").requestSubmit();
  }
}
