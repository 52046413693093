import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="utils"
export default class extends Controller {
  static values = {
    bgClass: String,
  };

  highlight() {
    if (this.bgClassValue) {
      this.element.classList.remove(bgClass);
    }
    this.element.classList.add("bg-yellow-50");
  }
}
