import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="navigation"
export default class extends Controller {
  navigate() {
    const selectedOptions = this.element.selectedOptions;
    if (selectedOptions.length > 0) {
      var option = selectedOptions[0];
      if (option.dataset.href) {
        Turbo.visit(option.dataset.href);
      }
    }
  }
}
