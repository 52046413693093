// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import Clipboard from "stimulus-clipboard";

import TurboPower from "turbo_power";
TurboPower.initialize(Turbo.StreamActions);

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import LocalTime from "@marcoroth/local-time";
LocalTime.start();

import { Application } from "@hotwired/stimulus";
const application = Application.start();

import { registerControllers } from "stimulus-vite-helpers";

const controllers = import.meta.globEager(
  "~/javascript/controllers/**/*_controller.js"
);
registerControllers(application, controllers);

application.register("clipboard", Clipboard);
